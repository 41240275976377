@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("poppins-devanagari-600-normal.88f3e07a.woff2") format("woff2"), url("poppins-all-600-normal.7a74bfcc.woff") format("woff");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("poppins-latin-ext-600-normal.093466a3.woff2") format("woff2"), url("poppins-all-600-normal.7a74bfcc.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("poppins-latin-600-normal.414c1233.woff2") format("woff2"), url("poppins-all-600-normal.7a74bfcc.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=dashboard.7dda30a2.css.map */
